import React, { useState, useEffect } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";

import { Helmet } from "react-helmet";

import { Heading, Group, ProgressBar } from "@peracto/peracto-ui";
import { GET_ONE, GET_LIST, useClient } from "@peracto/client";
import { useConfig } from "@peracto/peracto-config";
import { IMPORT_TYPES, PRODUCT_VARIANT_IMPORT_TYPES } from "../util";
import classNames from "classnames";
import styled from "styled-components";

import ProductImportStatusList from "./ProductImportStatusList";

const ImportSummaryPod = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 3px;
  font-size: 16px;
`;

const ImportSummary = ({ summaryTitle, total, className = "" }) => (
  <ImportSummaryPod className={className}>
    <span className="font-weight-bold">{summaryTitle}</span>
    <span>{total}</span>
  </ImportSummaryPod>
);

const ProductImportStatus = () => {
  const { client } = useClient();
  const config = useConfig();

  const [importSummaryData, setImportSummaryData] = useState();
  const [importLinesData, setImportLinesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState();
  const [countdown, setCountdown] = useState();
  const [countdownTimer, setCountdownTimer] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [importFailed, setImportFailed] = useState(false);
  const [timeAtPageLoad, setTimeAtPageLoad] = useState();

  const { products } = config.get("features", {});

  const importTypes = [...IMPORT_TYPES];

  if (products?.productVariants) {
    importTypes.push(...PRODUCT_VARIANT_IMPORT_TYPES);
  }

  if (
    Array.isArray(products?.customImportTypes) &&
    products.customImportTypes?.length > 0
  ) {
    importTypes.push(...products.customImportTypes);
  }

  const pageTitle = !loading
    ? `${
        importTypes.find((type) => importSummaryData.importType === type.value)
          .label
      } Import`
    : "Loading...";

  const { id } = useParams();
  const filter = showAll
    ? { import: id, successful: true }
    : { import: id, successful: false };

  const fetchImportStatus = async () => {
    try {
      const { data: importSummary, response: importSummaryReponse } =
        await client(GET_ONE, "imports", {
          id: `/imports/${id}`,
        });

      const { data: importLines } = await client(GET_LIST, `import-lines`, {
        id: `/import-lines`,
        filter,
      });

      setImportSummaryData(importSummary);
      setImportLinesData(importLines);
      setLoading(false);

      const longRunning = new Date().getTime() - timeAtPageLoad > 90000;
      const timer =
        importSummary?.processedCount > 0
          ? longRunning
            ? 20000
            : 10000
          : 5000;

      setCountdown(new Date().getTime() + timer);

      if (importSummaryReponse.status === 404) {
        setRedirect("/products/import");
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setRedirect("/products/import");
    }
  };

  useEffect(() => {
    const longRunning = new Date().getTime() - timeAtPageLoad > 90000;
    const timer =
      importSummaryData?.processedCount > 0
        ? longRunning
          ? 20000
          : 10000
        : 5000;

    let hourDifference =
      (new Date().getTime() -
        new Date(importSummaryData?.createdAt).getTime()) /
      1000;
    hourDifference /= 60 * 60;

    const unknownImportFailure =
      hourDifference >= 2 &&
      Math.round(
        (importSummaryData?.processedCount / importSummaryData?.totalCount) *
          100
      ) < 100;

    setImportFailed(unknownImportFailure);

    const interval = setInterval(() => {
      if (
        importSummaryData?.totalCount !== importSummaryData?.processedCount &&
        !unknownImportFailure
      ) {
        fetchImportStatus();
      } else {
        clearInterval(interval);
      }
    }, timer);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importSummaryData]);

  useEffect(() => {
    fetchImportStatus();
    const d = new Date();
    setTimeAtPageLoad(d.getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchImportStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (importSummaryData?.totalCount === importSummaryData?.processedCount) {
        setCountdownTimer(null);
        clearInterval(interval);
      } else {
        const time = new Date().getTime();
        setCountdownTimer(countdown - time);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importSummaryData, countdown]);

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Helmet>
        <title>{pageTitle} | Peracto</title>
      </Helmet>

      <div className="form-container">
        <Heading name={pageTitle}>
          <div className="d-flex align-items-center ml-2">
            <Link className="btn btn-outline-primary" to="/products/import">
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back to Product Imports
            </Link>
          </div>
        </Heading>

        <Group key="import-summary" id="import-summary" name="Summary">
          {!loading ? (
            <>
              {importFailed ? (
                <>
                  <div className="alert alert-danger">
                    This import failed to complete. Please try again.
                  </div>
                </>
              ) : (
                <>
                  {countdownTimer &&
                    Math.round(
                      (importSummaryData.processedCount /
                        importSummaryData.totalCount) *
                        100
                    ) < 100 && (
                      <div className="d-flex justify-content-end align-items-center my-1">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          className="mr-2"
                        />
                        <span>
                          {importSummaryData?.processedCount === 0 &&
                            "Adding import to queue - "}
                          Next update in {Math.ceil(countdownTimer / 1000)}{" "}
                          seconds
                        </span>
                      </div>
                    )}
                  <ProgressBar
                    total={importSummaryData.totalCount}
                    current={importSummaryData.processedCount}
                  />
                </>
              )}

              <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                  <ImportSummary
                    summaryTitle="Total to Process"
                    total={importSummaryData.totalCount}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <ImportSummary
                    summaryTitle="Processed"
                    total={importSummaryData.processedCount}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <ImportSummary
                    summaryTitle="Successfully Imported"
                    total={importSummaryData.successfulCount}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <ImportSummary
                    summaryTitle="Import Errors"
                    total={importSummaryData.errorCount}
                    className={classNames({
                      "text-danger": importSummaryData.errorCount > 0,
                    })}
                  />
                </div>
              </div>
            </>
          ) : (
            "Loading data..."
          )}
        </Group>

        {importSummaryData?.errors?.length > 0 && (
          <Group key="file-errors" id="file-errors" name="File Errors">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Error Message</th>
                  <th>Extra Data</th>
                </tr>
              </thead>

              <tbody>
                {importSummaryData.errors.map((error) => (
                  <tr>
                    <td>{error.message || "-"}</td>
                    <td>
                      {error.extra_data
                        ? JSON.stringify(error.extra_data)
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Group>
        )}

        <Group key="import-lines" id="import-lines" name="Modified Lines">
          {!loading ? (
            <>
              <div className="d-flex justify-content-end my-2">
                <button
                  className="btn btn-link"
                  onClick={() => setShowAll(!showAll)}
                >
                  <FontAwesomeIcon icon={faEye} className="mr-2" />
                  {showAll
                    ? "Show Import Line Errors"
                    : "Show Successful Modified Lines"}
                </button>
              </div>
              {importLinesData?.length > 0 ? (
                <ProductImportStatusList
                  data={importLinesData}
                  filter={filter}
                  showAll={showAll}
                />
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center my-4">
                  <p className="text-center mb-0">
                    No Import {showAll ? "Lines" : "Line Errors"}
                  </p>
                </div>
              )}
            </>
          ) : (
            "Loading data..."
          )}
        </Group>
      </div>
    </>
  );
};

export default ProductImportStatus;
