import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/pro-regular-svg-icons/faImages";

export const defaultTranslations = {
  pluginName: "Images Carousel",
  pluginDescription: "Insert an Images carousel containing 3 or more images.",
};

export const defaultSettings = {
  Controls: () => <>Controls for this plugin were not provided</>,
  Renderer: () => <>Renderer for this plugin was not provided</>,
  translations: defaultTranslations,
  IconComponent: <FontAwesomeIcon icon={faImages} size="3x" />,
};
