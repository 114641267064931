import { css } from "@emotion/core";

export const theme = {
  breakpoints: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 992,
    desktop: 1024,
    desktopL: 1700,
  },
};

const body = css`
  body {
    color: #3d3935;
  }
`;

const ory = css`
  .ory-row {
    button {
      background-color: #c5b683;
      color: #ffffff;
      font-size: 12px;
      height: 50px;
      border-radius: 0;
      line-height: 45px;
      border: 1px solid #c5b683;
      text-transform: uppercase;
      width: 100%;
      font-weight: bold;
      &:hover {
        background-color: #ffffff;
        color: #3d3935;
        border: 1px solid #c5b683;
      }
    }
    input {
      border: 1px solid #cbcaca;
    }
    p {
      margin-bottom: 15px;
    }
  }
`;

const headings = css`
  h1 {
    font-size: 40px;
    line-height: 36px;
    margin-bottom: 10px;

    @media (max-width: 478px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 15px;

    @media (max-width: 478px) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 30px;

    @media (max-width: 478px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
  }

  h5 {
    font-size: 16px;
    line-height: 22px;
  }

  h6 {
    font-size: 14px;
    line-height: 20px;
  }
`;

// For <a> tags inside text from content editor. We cannot make
// this globally apply to all <a> tags because most of them
// should not have this styling.
const inlineLinks = css`
  p a {
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const inputs = css`
  input {
    border-radius: 0px !important;
    border-color: #cbcaca;
    color: #000;
    font-size: 12px;
    height: 45px !important;
    &::placeholder {
      color: #3d3935;
      font-size: 12px;
    }
  }
`;

const carousel = css`
  .carousel {
    ul {
      margin-bottom: 0;
      li {
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }

  .carousel .slide,
  .DayPicker {
    text-align: left;
    background: inherit;

    ul {
      margin-bottom: 0;
      li {
        padding-left: 0;
        &:before {
          content: none;
        }
      }
    }
  }

  .carousel.carousel-slider {
    overflow: visible;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .control-dots {
    display: flex;
    justify-content: center;
  }

  ul.indicators > li::before {
    content: unset;
  }
`;
const orderedList = css`
  .ory-row {
    ol {
      list-style: none;
      counter-reset: my-awesome-counter;
    }
    ol li {
      counter-increment: my-awesome-counter;
      font-size: 18px;
    }
    ol li::before {
      content: counter(my-awesome-counter) ". ";
      color: #6b1d2f;
      font-weight: bold;
      font-size: 18px;
    }
  }
`;

const unorderedList = css`
  ul {
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    position: relative;
    li {
      line-height: 30px;
      padding-left: 20px;
      position: relative;
      &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        height: 10px;
        width: 10px;
        margin-right: 10px;
        background-color: #6b1d2f;
      }
    }
  }
`;

const tables = css`
  .ck-content .table table th {
    background: transparent;
    border: 0;
  }
  .ck-content .table table td {
    border: 0;
  }
  .ck-content .table {
    tbody > tr {
      border-top: 1px solid #e2e8f0;
    }
    thead > tr > th {
      background-color: #6a1d2f;
      color: #fff;
    }
    tbody > tr:nth-child(even) {
      background: #f2f2f2;
    }
  }
`;

const buttons = css`
  a[type="button"] {
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0px;
    font-size: 12px;
    font-weight: bold;
  }
`;

const customScrollbar = css`
  .ps__rail-y,
  .ps__thumb-y {
    opacity: 1;
  }
  .ps__thumb-y {
    width: 11px;
  }
`;

export const GlobalStyles = css`
  ${tables}
  ${body}    
    ${ory}
    ${headings}
    ${customScrollbar}
    ${buttons}
    ${unorderedList}
    ${orderedList}
    ${carousel}
    ${inputs}
    ${inlineLinks}
`;
