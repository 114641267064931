import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faUpload } from "@fortawesome/pro-regular-svg-icons/faUpload";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faImage } from "@fortawesome/pro-regular-svg-icons/faImage";

import classNames from "classnames";
import { Helmet } from "react-helmet";
import {
    HydraTable,
    Heading,
    TextFilter,
    SelectFilter,
    DateRangeFilter,
    StyledCheckbox,
    TimeAgo,
} from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";
import { useSettings } from "@peracto/peracto-hooks";
import startCase from "lodash/startCase";
import { convertObjectToOptions, DEFAULT_KITCHEN_STATUS_VALUES } from "../util";

const KitchenImage = ({ src, name }) => {
    return !!src ? (
        <img
            src={src}
            title={name}
            alt={name}
            style={{ height: "40px", width: "40px" }}
        />
    ) : (
        <FontAwesomeIcon
            icon={faImage}
            style={{ width: "40px", height: "40px", color: "#606060" }}
        />
    );
};

const KitchensList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({
        createdAt: false,
    });

    const { client } = useClient();
    const config = useConfig();
    const formatDate = config.get(
        "user_format_date",
        config.get("format_date")
    );
    const { kitchens } = config.get("features", {});
    const readOnly = kitchens?.readOnly;

    const { values } = useSettings();

    const STATUS_OPTIONS =
        values?.status?.length > 0
            ? convertObjectToOptions(values.status)
            : DEFAULT_KITCHEN_STATUS_VALUES;

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    // eslint-disable-next-line
    const columns = useMemo(() => [
        {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
                <StyledCheckbox
                    {...row.getToggleRowSelectedProps()}
                    data-testid={`row-${row.index}-checkbox`}
                />
            ),
            selectable: false,
        },
        {
            accessor: "primaryImage",
            Cell: (row) => {
                return (
                    <Link to={`/kitchens/${row.row.original.originId}`}>
                        <KitchenImage
                            src={row.cell.value?.location}
                            name={row.row.values.name}
                        />
                    </Link>
                );
            },
            selectable: false,
        },
        {
            Header: "Kitchen",
            accessor: "name",
            id: "kitchenName",
            sortType: "basic",
            Filter: (props) => <TextFilter label="Name" {...props} />,
            Cell: ({ row, cell: { value } }) => (
                <Link
                    to={`/kitchens/${row.original.originId}`}
                    data-testid={`row-${row.index}-name`}
                >
                    {value}
                </Link>
            ),
            selectable: false,
        },
        {
            Header: "SKU",
            accessor: "sku",
            sortType: "basic",
            Filter: (props) => <TextFilter label="SKU" {...props} />,
            selectable: false,
        },
        {
            Header: "Status",
            accessor: "status",
            sortType: "basic",
            Filter: (props) => (
                <SelectFilter
                    label="Status"
                    options={STATUS_OPTIONS}
                    {...props}
                />
            ),
            Cell: ({ cell: { value } }) => (
                <span
                    className={classNames("badge", "badge-pill", {
                        "badge-info": value === "draft",
                        "badge-success": value === "active",
                        "badge-danger": value === "deleted",
                    })}
                >
                    {startCase(value)}
                </span>
            ),
            selectable: false,
        },
        {
            Header: "Last Modified",
            accessor: "modifiedAt",
            sortType: "basic",
            Filter: (props) => (
                <DateRangeFilter label="Last Modified" {...props} />
            ),
            Cell: ({ cell: { value } }) => {
                const date = new Date(value);
                const validDate = !!date.getDate() === true;

                return value && validDate ? (
                    <TimeAgo title={formatDate(new Date(value))} date={value} />
                ) : (
                    "-"
                );
            },
            selectable: false,
        },

        {
            Header: "Created",
            accessor: "createdAt",
            sortType: "basic",
            Filter: (props) => <DateRangeFilter label="Created" {...props} />,
            Cell: ({ cell: { value } }) => {
                const date = new Date(value);
                const validDate = !!date.getDate() === true;

                return value && validDate ? (
                    <TimeAgo title={formatDate(new Date(value))} date={value} />
                ) : (
                    "-"
                );
            },
            selectable: true,
            show: selectedColumns["createdAt"],
        },
    ]);

    const onFetchData = (options) => client(GET_LIST, "kitchens", options);

    return (
        <>
            <Helmet>
                <title>User Generated Content | Peracto</title>
            </Helmet>
            <Heading name="User Generated Content">
                <div className="d-flex align-items-center ml-2">
                    {kitchens?.exportKitchenData === true && (
                        <Link
                            className="btn btn-link mr-2"
                            to="/kitchens/export"
                            data-testid="export-kitchen-data"
                        >
                            <FontAwesomeIcon
                                icon={faDownload}
                                className="mr-2"
                            />
                            Export User Generated Content Data
                        </Link>
                    )}

                    <Link
                        className="btn btn-outline-primary mr-2"
                        to="/kitchens/import"
                        data-testid="bulk-manage-data"
                    >
                        <FontAwesomeIcon icon={faUpload} className="mr-2" />
                        Bulk Manage Data
                    </Link>

                    {!readOnly && (
                        <Link
                            className="btn btn-primary"
                            to="/kitchens/add"
                            data-testid="add-kitchen"
                        >
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="mr-2"
                            />
                            Add User Generated Content
                        </Link>
                    )}
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    title: "",
                                    sku: "",
                                    status: "active",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default KitchensList;
