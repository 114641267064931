export const primary = {
  accentColor: "rgb(107,29,47)",
  textColor: "rgba(255,255,255)",
  sharedProps: {
    px: "1em",
    lineHeight: "45px",
    height: "50px",
    borderRadius: "0px",
    borderColor: "rgb(107,29,47)",
    border: "1px solid",
    minWidth: "2.5rem",
    paddingX: "80px",
  },
  solidProps: {
    color: "white",
    _hover: {
      border: "1px solid",
      borderColor: "rgb(107,29,47)",
      backgroundColor: "rgba(255,255,255)",
      color: "rgb(107,29,47)",
    },
  },
};

export const secondary = {
  accentColor: "rgb(61,57,53)",
  textColor: "rgba(255,255,255)",
  sharedProps: {
    px: "1em",
    lineHeight: "45px",
    height: "50px",
    borderRadius: "0px",
    borderColor: "rgb(107,29,47)",
    border: "1px solid",
    minWidth: "2.5rem",
    paddingX: "80px",
  },
  solidProps: {
    color: "white",
    _hover: {
      border: "1px solid",
      borderColor: "rgb(61,57,53)",
      backgroundColor: "rgba(255,255,255)",
      color: "rgb(61,57,53)",
    },
  },
  outlineProps: {
    backgroundColor: "brand.white",
  },
};

export const tertiary = {
  accentColor: "rgb(197, 182, 131)",
  textColor: "rgb(61,57,53)",
  sharedProps: {
    px: "1em",
    lineHeight: "45px",
    height: "50px",
    borderRadius: "0px",
    borderColor: "rgb(197, 182, 131)",
    border: "1px solid",
    minWidth: "2.5rem",
    paddingX: "80px",
  },
  solidProps: {
    color: "white",
    _hover: {
      border: "1px solid",
      borderColor: "rgb(197, 182, 131)",
      backgroundColor: "rgba(255,255,255)",
      color: "rgb(61,57,53)",
    },
  },
  outlineProps: {
    backgroundColor: "brand.white",
  },
};
