import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons/faArrowCircleLeft'
import { faCloudDownloadAlt } from '@fortawesome/pro-regular-svg-icons/faCloudDownloadAlt'
import { Helmet } from 'react-helmet'
import { Field } from 'formik'
import axios from 'axios'
import debounce from 'debounce-promise'
import download from 'downloadjs'
import { useSettings } from '@peracto/peracto-hooks'
import capitalize from 'lodash/capitalize'
import { GET_LIST, useClient } from '@peracto/client'
import { Heading, Group, Form, Select, Input } from '@peracto/peracto-ui'
import { useConfig } from '@peracto/peracto-config'
import AsyncSelect from 'react-select/async'
import { convertObjectToOptions } from '../util'

import { DEFAULT_PRODUCT_AVAILABILITY_VALUES, DEFAULT_PRODUCT_STATUS_VALUES } from '../util'

const ProductExport = () => {
    const config = useConfig()
    const API_URL = config.get('api')
    const { products } = config.get('features', {})

    const { client } = useClient()

    const {
        values: { relationship_types, resource_types, availability, status },
    } = useSettings()

    const AVAILABILITY_OPTIONS =
        availability?.length > 0
            ? convertObjectToOptions(availability)
            : DEFAULT_PRODUCT_AVAILABILITY_VALUES

    const STATUS_OPTIONS =
        status?.length > 0 ? convertObjectToOptions(status) : DEFAULT_PRODUCT_STATUS_VALUES

    const resourceTypes = resource_types || []
    const relationshipTypes = relationship_types || []

    const fetchAttributeSets = async inputValue => {
        const { data } = await client(GET_LIST, 'attribute-sets', {
            id: 'attribute-sets',
            filter: {
                name: inputValue,
            },
        })

        const values = data.map(attr => ({
            label: attr.name,
            value: attr.name,
        }))

        return [{ label: 'All', value: '' }, ...values]
    }

    const debouncedfetchAttributeSets = debounce(fetchAttributeSets, 200)

    const fetchProducts = async inputValue => {
        const { data } = await client(GET_LIST, 'products', {
            id: 'products',
            filter: {
                name: inputValue,
            },
        })

        const values = data.map(product => ({
            label: product.name,
            value: product.sku,
        }))

        return [{ label: 'All', value: '' }, ...values]
    }

    const debouncedfetchProducts = debounce(fetchProducts, 200)

    // Prevent enter key submitting form
    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault()
        }
    }

    const handleSubmit = async (type, values = {}) => {
        const filteredValues = {}

        for (const [field, value] of Object.entries(values)) {
            if (!!value) {
                filteredValues[field] = value
            }
        }

        let filename = `${type}.csv?${new URLSearchParams(filteredValues).toString()}`

        const { data } = await axios.get(`${API_URL}/export/${filename}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })

        const filenameDetails = Object.values(filteredValues).join('_')

        download(data, `${type}_export${filenameDetails ? `_${filenameDetails}` : ''}.csv`)
    }

    return (
        <>
            <Helmet>
                <title>Export Product Data | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Export Product Data">
                    <div className="d-flex align-items-center ml-2">
                        <Link
                            className="btn btn-outline-primary"
                            to="/products"
                            data-testid="export-product-data"
                        >
                            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                            Back to Products
                        </Link>
                    </div>
                </Heading>

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <Group key="products" id="products" name="Products">
                            <Form
                                values={{
                                    attributeSet: '',
                                    status: '',
                                    availability: '',
                                }}
                                showActionPanel={false}
                                onSubmit={values => handleSubmit('product', values)}
                                onKeyDown={onKeyDown}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <Field name="attributeSet">
                                            {({ form, field }) => (
                                                <div className="form-group">
                                                    <label className="mb-1">Attribute Set</label>
                                                    <AsyncSelect
                                                        className="w-100"
                                                        classNamePrefix="list"
                                                        loadOptions={input =>
                                                            debouncedfetchAttributeSets(input)
                                                        }
                                                        isSearchable={true}
                                                        defaultOptions={true}
                                                        onChange={option => {
                                                            form.setFieldValue(
                                                                field.name,
                                                                option.value
                                                            )
                                                        }}
                                                        placeholder="All"
                                                        noOptionsMessage={({ inputValue }) => {
                                                            if (inputValue.length > 0) {
                                                                return `No results found for '${inputValue}'.`
                                                            } else {
                                                                return 'Enter text to begin searching.'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>

                                        <Select
                                            name="status"
                                            label="Product Status"
                                            options={[
                                                { label: 'All', value: '' },
                                                ...STATUS_OPTIONS,
                                            ]}
                                        />

                                        <Select
                                            name="availability"
                                            label="Availability"
                                            options={[
                                                { label: 'All', value: '' },
                                                ...AVAILABILITY_OPTIONS,
                                            ]}
                                        />

                                        <button
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-testid="export-products"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                                className="mr-2"
                                            />
                                            Export Products
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Group>
                    </div>

                    {products?.productVariants && (
                        <div className="col-12 col-lg-6">
                            <Group key="variants" id="variants" name="Variants">
                                <Form
                                    showActionPanel={false}
                                    onSubmit={values => handleSubmit('product_variant', values)}
                                    values={{
                                        sku: '',
                                        status: '',
                                        availability: '',
                                    }}
                                >
                                    <Field name="sku">
                                        {({ form, field }) => (
                                            <div className="form-group">
                                                <label className="mb-1">Product SKU</label>
                                                <AsyncSelect
                                                    className="w-100"
                                                    classNamePrefix="list"
                                                    loadOptions={input =>
                                                        debouncedfetchProducts(input)
                                                    }
                                                    isSearchable={true}
                                                    defaultOptions={true}
                                                    onChange={option => {
                                                        form.setFieldValue(field.name, option.value)
                                                    }}
                                                    placeholder="All"
                                                    noOptionsMessage={({ inputValue }) => {
                                                        if (inputValue.length > 0) {
                                                            return `No results found for '${inputValue}'.`
                                                        } else {
                                                            return 'Enter text to begin searching.'
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Field>

                                    <Select
                                        name="status"
                                        label="Product Status"
                                        options={[{ label: 'All', value: '' }, ...STATUS_OPTIONS]}
                                    />

                                    <Select
                                        name="availability"
                                        label="Availability"
                                        options={[
                                            { label: 'All', value: '' },
                                            ...AVAILABILITY_OPTIONS,
                                        ]}
                                    />

                                    <div className="row">
                                        <div className="col-12">
                                            <button
                                                className="btn btn-outline-primary"
                                                type="submit"
                                                data-testid="export-variants"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCloudDownloadAlt}
                                                    className="mr-2"
                                                />
                                                Export Variants
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </Group>
                        </div>
                    )}

                    <div className="col-12 col-lg-6">
                        <Group key="attributes" id="attributes" name="Attribute Values">
                            <Form
                                values={{
                                    attributeSet: '',
                                    offset: 1,
                                    limit: 100,
                                }}
                                showActionPanel={false}
                                onSubmit={values => handleSubmit('product_attribute', values)}
                                onKeyDown={onKeyDown}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <Field name="attributeSet">
                                            {({ form, field }) => (
                                                <div className="form-group">
                                                    <label className="mb-1">Attribute Set</label>
                                                    <AsyncSelect
                                                        className="w-100"
                                                        classNamePrefix="list"
                                                        loadOptions={input =>
                                                            debouncedfetchAttributeSets(input)
                                                        }
                                                        isSearchable={true}
                                                        defaultOptions={true}
                                                        onChange={option => {
                                                            form.setFieldValue(
                                                                field.name,
                                                                option.value
                                                            )
                                                        }}
                                                        placeholder="All"
                                                        noOptionsMessage={({ inputValue }) => {
                                                            if (inputValue.length > 0) {
                                                                return `No results found for '${inputValue}'.`
                                                            } else {
                                                                return 'Enter text to begin searching.'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>

                                        <Input
                                            name="offset"
                                            type="number"
                                            min="1"
                                            label="Starting Row"
                                            help="Used to determine the first row to return in export"
                                        />

                                        <Input
                                            name="limit"
                                            type="number"
                                            min="1"
                                            label="Number of Rows"
                                        />

                                        <button
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-testid="export-attributes"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                                className="mr-2"
                                            />
                                            Export Attributes
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Group>
                    </div>

                    <div className="col-12 col-lg-6">
                        <Group key="resources" id="resources" name="Resources">
                            <Form
                                values={{
                                    type: '',
                                    offset: 1,
                                    limit: 100,
                                }}
                                showActionPanel={false}
                                onSubmit={values => handleSubmit('product_resource', values)}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <Select
                                            name="type"
                                            label="Type"
                                            options={[
                                                { label: 'All', value: '' },
                                                ...resourceTypes.map(type => {
                                                    return {
                                                        label: capitalize(type.replace(/_/g, ' ')),
                                                        value: type,
                                                    }
                                                }),
                                            ]}
                                        />

                                        <Input
                                            name="offset"
                                            type="number"
                                            min="1"
                                            label="Starting Row"
                                            help="Used to determine the first row to return in export"
                                        />
                                    </div>

                                    <div className="col-12">
                                        <Input
                                            name="limit"
                                            type="number"
                                            min="1"
                                            label="Number of Rows"
                                        />

                                        <button
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-testid="export-resources"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                                className="mr-2"
                                            />
                                            Export Resources
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Group>
                    </div>

                    <div className="col-12 col-lg-6">
                        <Group key="relationships" id="relationships" name="Relationships">
                            <Form
                                values={{
                                    relationship: '',
                                }}
                                showActionPanel={false}
                                onSubmit={values => handleSubmit('product_relation', values)}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <Select
                                            name="relationship"
                                            label="Relationship"
                                            options={[
                                                { label: 'All', value: '' },
                                                ...relationshipTypes.map(type => {
                                                    return {
                                                        label: capitalize(type.replace(/_/g, ' ')),
                                                        value: type,
                                                    }
                                                }),
                                            ]}
                                        />

                                        <button
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-testid="export-relationships"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                                className="mr-2"
                                            />
                                            Export Relationships
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Group>
                    </div>

                    <div className="col-12 col-lg-6">
                        <Group
                            key="product-inventory"
                            id="product-inventory"
                            name="Product Inventory"
                        >
                            <Form
                                showActionPanel={false}
                                onSubmit={() => handleSubmit('product_inventory')}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-testid="export-product-inventory"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                                className="mr-2"
                                            />
                                            Export Product Inventory
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Group>
                    </div>

                    {products?.productVariants && (
                        <>
                            <div className="col-12 col-lg-6">
                                <Group
                                    key="product-options"
                                    id="product-options"
                                    name="Product Options"
                                >
                                    <Form
                                        showActionPanel={false}
                                        onSubmit={() => handleSubmit('product_option')}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="submit"
                                                    data-testid="export-product-options"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCloudDownloadAlt}
                                                        className="mr-2"
                                                    />
                                                    Export Product Options
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Group>
                            </div>
                            <div className="col-12 col-lg-6">
                                <Group
                                    key="product-variant-options"
                                    id="product-variant-options"
                                    name="Product Variant Options"
                                >
                                    <Form
                                        showActionPanel={false}
                                        onSubmit={() => handleSubmit('product_variant_option')}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="submit"
                                                    data-testid="export-product-variant-options"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCloudDownloadAlt}
                                                        className="mr-2"
                                                    />
                                                    Export Product Variant Options
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Group>
                            </div>

                            <div className="col-12 col-lg-6">
                                <Group
                                    key="product-variant-inventory"
                                    id="product-variant-inventory"
                                    name="Product Variant Inventory"
                                >
                                    <Form
                                        showActionPanel={false}
                                        onSubmit={() => handleSubmit('product_variant_inventory')}
                                    >
                                        <div className="row">
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="submit"
                                                    data-testid="export-product-variant-inventory"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCloudDownloadAlt}
                                                        className="mr-2"
                                                    />
                                                    Export Product Variant Inventory
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Group>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default ProductExport
