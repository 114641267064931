/* eslint-disable */
import React, { useEffect } from "react";
import { useConfig } from "@peracto/peracto-config";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import File from "@peracto/peracto-editor-ui/dist/File";
import Select from "@peracto/peracto-editor-ui/dist/Select";
import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";
import { Text } from "@chakra-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import startCase from "lodash/startCase";

const ImagesCarouselForm = ({
  onChange,
  state: {
    showArrows = true,
    arrowsColour = "#bababa",
    showIndicators = false,
    showTexts = false,
    textColour = "#bababa",
    imageSize = "500x400",
    indicatorsPosition = "below",
    indicatorsColour = "#000",
    images = [],
    selectedImageIndex,
  },
}) => {
  const DEFAULT_IMAGE = {
    url: "https://via.placeholder.com/600x200",
    alt: "",
    title: "",
    link: "",
    openInNewTab: false,
    mainText: "",
    subText: "",
    link: "",
  };

  const config = useConfig();
  const { theme } = config.get("editorConfig") || [];

  const themeColours = [];

  const ImageSizes = [
    { label: "500 x 400", value: "500x400" },
    { label: "350 x 250", value: "350x250" },
    { label: "250 x 250", value: "250x250" },
  ];

  if (theme?.colors?.brand) {
    for (const [label, colour] of Object.entries(theme.colors.brand)) {
      themeColours.push({
        label: startCase(label),
        value: colour,
      });
    }
  }

  useEffect(() => {
    return () => {
      onChange({ selectedImageIndex: 0 });
    };
  }, []);

  return (
    <>
      <Checkbox
        label="Show Arrows"
        checked={showArrows}
        onChange={(e) => onChange({ showArrows: e.target.checked })}
      />

      {showArrows && (
        <Select
          label="Arrows Colour"
          options={themeColours}
          value={arrowsColour}
          onChange={(e) => onChange({ arrowsColour: e.value })}
        />
      )}
      <hr />

      <Checkbox
        label="Show Indicators"
        checked={showIndicators}
        onChange={(e) => onChange({ showIndicators: e.target.checked })}
      />

      {showIndicators && (
        <>
          <Select
            label="Indicators Position"
            options={[
              { label: "Below", value: "below" },
              { label: "Above", value: "above" },
            ]}
            value={indicatorsPosition}
            onChange={(e) => onChange({ indicatorsPosition: e.value })}
          />

          <Select
            label="Indicators Colour"
            options={themeColours}
            value={indicatorsColour}
            onChange={(e) => onChange({ indicatorsColour: e.value })}
          />
        </>
      )}
      <hr />

      <Checkbox
        label="Show Texts"
        checked={showTexts}
        onChange={(e) => onChange({ showTexts: e.target.checked })}
      />
      {showTexts && (
        <Select
          label="Texts Colour"
          options={themeColours}
          value={textColour}
          onChange={(e) => onChange({ textColour: e.value })}
        />
      )}
      <hr />
      <Select
        label="Image Size"
        options={ImageSizes}
        value={imageSize}
        onChange={(e) => onChange({ imageSize: e.value })}
      />
      <hr />
      {selectedImageIndex !== null &&
        images &&
        images.map((image, index) => (
          <>
            <Text
              fontSize={"18px"}
              marginTop={"16px"}
              fontWeight={"400"}
              lineHeight={"20px"}
            >
              {`Slide ${index + 1}`}
            </Text>

            <File
              label="Image"
              onChange={(value) => {
                const imagesClone = [...images];
                imagesClone[index].url = value;
                onChange({
                  images: imagesClone,
                });
              }}
              value={images[index].url || ""}
              isInline={false}
            />

            <TextInput
              label="Image alt text"
              onChange={(e) => {
                const imagesClone = [...images];
                imagesClone[index].alt = e.target.value;

                onChange({
                  images: imagesClone,
                });
              }}
              value={images[index].alt || ""}
            />
            {showTexts && (
              <>
                <TextInput
                  label="Main text"
                  onChange={(e) => {
                    const imagesClone = [...images];
                    imagesClone[index].mainText = e.target.value;

                    onChange({
                      images: imagesClone,
                    });
                  }}
                  value={images[index].mainText || ""}
                />

                <TextInput
                  label="Sub text"
                  onChange={(e) => {
                    const imagesClone = [...images];
                    imagesClone[index].subText = e.target.value;

                    onChange({
                      images: imagesClone,
                    });
                  }}
                  value={images[index].subText || ""}
                />
              </>
            )}
            <hr />
            <TextInput
              label="Image link"
              onChange={(e) => {
                const imagesClone = [...images];
                imagesClone[index].link = e.target.value;

                onChange({
                  images: imagesClone,
                });
              }}
              value={images[index].link || ""}
            />
            <hr />

            <Checkbox
              label="Open in new tab"
              checked={images[index].openInNewTab}
              onChange={(e) => {
                const imagesClone = [...images];
                imagesClone[index].openInNewTab = e.target.checked;
                onChange({
                  images: imagesClone,
                });
              }}
            />

            <button
              className="mt-2 btn btn-sm btn-danger w-100"
              onClick={(e) => {
                const imagesClone = [...images];

                imagesClone.splice(index, 1);

                onChange({
                  images: imagesClone,
                });
                onChange({
                  index: index > 0 ? index - 1 : 0,
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="mr-2" />{" "}
              {`Remove Slide ${index + 1}`}
            </button>

            <hr className="my-2" />
          </>
        ))}

      <hr className="my-2" />

      <button
        className="btn btn-sm btn-secondary w-100"
        onClick={(e) => {
          const imagesClone = [...images, DEFAULT_IMAGE];
          onChange({
            images: imagesClone,
          });
          onChange({
            selectedImageIndex: imagesClone.length - 1,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add Image
      </button>
    </>
  );
};

export default ImagesCarouselForm;
