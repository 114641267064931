import createPlugin from "./createPlugin";

import ImagesCarouselRenderer from "./Renderer/ImagesCarouselRenderer";

const carouselPlugin = (settings) =>
  createPlugin({
    Renderer: ImagesCarouselRenderer,
    ...settings,
  });

const carousel = carouselPlugin();
export default carousel;

export { carouselPlugin, ImagesCarouselRenderer };
