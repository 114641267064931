import React from "react";
import { Route, Switch } from "react-router-dom";

import KitchensList from "./KitchensList";
import KitchenAdd from "./KitchenAdd";
import KitchenEdit from "./KitchenEdit";
import KitchenForm from "./KitchenForm";
import ProductImport from "./ProductImport";
import ProductImportStatus from "./ProductImportStatus";
import ProductExport from "./ProductExport";

export default () => {
  return {
    routes: [
      {
        path: "/kitchens",
        render: ({ match }) => (
          <Switch>
            <Route path={`${match.url}`} exact component={KitchensList} />
            <Route
              path={`${match.url}/import`}
              exact
              component={ProductImport}
            />
            <Route
              path={`${match.url}/export`}
              exact
              component={ProductExport}
            />
            <Route
              path={`${match.url}/import/:id`}
              exact
              component={ProductImportStatus}
            />
            <Route path={`${match.url}/add`} exact>
              {(props) => <KitchenAdd {...props} KitchenForm={KitchenForm} />}
            </Route>
            <Route path={`${match.url}/:id`} exact>
              {(props) => <KitchenEdit {...props} KitchenForm={KitchenForm} />}
            </Route>
          </Switch>
        ),
      },
    ],
  };
};
