import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'

import { Modal } from '@peracto/peracto-ui'
import isEmpty from 'lodash/isEmpty'

const ProductMenuItemRelations = ({ setRedirect, onSubmit, disabled }) => {
    const [showModal, setShowModal] = useState()

    const { id } = useParams()

    const handleManageRelations = async form => {
        if (form.dirty) {
            setShowModal(true)
        } else {
            setRedirect(`/products/${id}/menu-item-relations`)
        }
    }

    return (
        <Field name={`product-menu-item-relations`}>
            {({ field, form }) => {
                return (
                    <div className="mb-0 form-group">
                        <div data-testid={`button-manage-menu-item-relations`}>
                            <button
                                className="btn btn-sm btn-outline-primary"
                                disabled={disabled}
                                onClick={e => {
                                    e.preventDefault()
                                    if (!disabled) {
                                        handleManageRelations(form)
                                    }
                                }}
                            >
                                Manage Menu Item Relations
                            </button>
                        </div>

                        <Modal
                            isVisible={showModal}
                            title="Showing Data"
                            close={() => setShowModal(false)}
                            buttons={[
                                {
                                    type: 'btn-outline-secondary',
                                    text: 'Cancel',
                                    action: () => {
                                        setShowModal(false)
                                    },
                                },
                                {
                                    type: 'btn-success',
                                    text: 'Save',
                                    action: async () => {
                                        const { values, ...actions } = form

                                        onSubmit(
                                            values,
                                            actions,
                                            `/products/${id}/menu-item-relations`
                                        )

                                        if (
                                            !form.isValidating &&
                                            !form.isSubmitting &&
                                            !isEmpty(form.errors)
                                        ) {
                                            setShowModal(false)
                                        }
                                    },
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faQuestionCircle}
                                size="4x"
                                className="mb-4 d-block"
                            />
                            Would you like to save the current changes to the form before
                            continuing?
                        </Modal>
                    </div>
                )
            }}
        </Field>
    )
}

export default ProductMenuItemRelations
