import React from "react";
import { Field, ErrorMessage } from "formik";

import * as S from "./styled";

const SearchEnginePreview = ({ path }) => {
  const kitchenLink = (slug) =>
    `${
      process.env.REACT_APP_STOREFRONT_URL || window.location.origin
    }/${path}/${slug || "kitchen-slug"}`;

  return (
    <S.SearchEnginePreview>
      <S.SearchEngineUrl>
        <Field name="slug">
          {({ field }) => (
            <div
              className="d-flex flex-column"
              data-testid="full-seo-preview-link"
            >
              {kitchenLink(field.value)}

              <ErrorMessage name={field.name}>
                {(msg) => (
                  <div
                    className="mt-2 error-text text-danger font-italic"
                    role="alert"
                  >
                    {msg}
                  </div>
                )}
              </ErrorMessage>
            </div>
          )}
        </Field>
      </S.SearchEngineUrl>
      <Field name="slug">
        {({ field }) => (
          <S.SearchEngineTitle
            href={kitchenLink(field.value)}
            target="_blank"
            data-testid="slug-preview-link"
            className="text-primary"
          >
            <Field name="seo_title">
              {({ field, form }) => {
                if (field?.value?.length) {
                  return field.value;
                } else {
                  return form?.values?.["title"]
                    ? form.values?.["title"]
                    : "Kitchen Name";
                }
              }}
            </Field>
          </S.SearchEngineTitle>
        )}
      </Field>

      <S.SearchEngineDescription>
        <Field name="attribute-seo_description">
          {({ field, form }) => {
            if (field?.value?.length) {
              return field.value;
            } else {
              return (
                <i>
                  You have no description set. Descriptions are important and
                  can influence click-through rates. The optimal length is
                  between 50–160 characters.
                </i>
              );
            }
          }}
        </Field>
      </S.SearchEngineDescription>
    </S.SearchEnginePreview>
  );
};

export default SearchEnginePreview;
