import React from "react";
import { Field, useFormikContext } from "formik";
import {
    TimeField,
    Select,
    Group,
    Input,
    Textarea,
    Checkbox,
    Tags,
    RichText,
} from "@peracto/peracto-ui";
import LocationImages from "./LocationImages";
import { useSettings } from "@peracto/peracto-hooks";
import sortBy from "lodash/sortBy";
import startCase from "lodash/startCase";

const DEFAULT_LOCATION_TYPES = [
    { label: "Branch", value: "branch" },
    { label: "Stockist", value: "stockist" },
    { label: "Warehouse", value: "warehouse" },
    { label: "Office", value: "office" },
    { label: "Click And Collect", value: "click_and_collect" },
];

export const LocationFormContent = ({ countries, days }) => {
    const getCountryOptions = () =>
        countries
            ? countries.map((country) => ({
                  label: country.label,
                  value: country.iso3,
              }))
            : [];

    const getCountryDefaultValue = (field, options) => {
        const found = options.find((option) => {
            return option.label === field.value;
        });
        return found || { label: "", value: "" };
    };

    const { values } = useFormikContext();
    const { values: p_values } = useSettings();

    const locationFeatures =
        p_values?.location_features?.length > 0
            ? sortBy(p_values?.location_features, ["display_order"])
            : [];

    const locationTypes =
        p_values?.location_types?.length > 0
            ? p_values.location_types.map((type) => {
                  return {
                      label: startCase(type.replace(/_/g, " ")),
                      value: type,
                  };
              })
            : DEFAULT_LOCATION_TYPES;

    return (
        <React.Fragment>
            <Group name="Location" id="location">
                <Input label="Location Name" required name="name" />

                <Input
                    label="Location Heading"
                    help="The text to be displayed in the heading on the branch's page on the storefront. If no heading is supplied then the Location Name will be used instead."
                    name="heading"
                    testId="location-heading"
                />

                <Tags
                    name="locationTypes"
                    label="Location Type"
                    options={locationTypes}
                />
                <Select
                    placeholder="Select Status"
                    name="locationStatus"
                    required
                    options={[
                        { label: "Open", value: "open" },
                        { label: "Closed", value: "closed" },
                        { label: "Opening Soon", value: "opening_soon" },
                        { label: "Closing Down", value: "closing_down" },
                    ]}
                    label="Status"
                />
                <Select
                    placeholder="Select Location Page"
                    name="locationPageStatus"
                    options={[
                        { label: "Active", value: "active" },
                        { label: "Inactive", value: "inactive" },
                    ]}
                    label="Location Page"
                    defaultValue={{ label: "Active", value: "active" }}
                />
                {(values.locationPageStatus === "active" ||
                    !values.locationPageStatus) && (
                    <Input
                        label="Alias"
                        required
                        name="slug"
                        help="The alias is used to construct a unique URL for use on the storefront"
                    />
                )}
            </Group>
            {(values.locationPageStatus === "active" ||
                !values.locationPageStatus) && (
                <Group name="Meta data" id="metaData">
                    <Input name="metaTitle" label="Title" />
                    <Textarea name="metaDescription" label="Description" />
                </Group>
            )}
            <Group name="Address" id="address">
                <Input name="address1" label="Address 1" />
                <Input name="address2" label="Address 2" />
                <Input name="address3" label="Address 3" />
                <Input name="city" label="Town / City" />
                <Input name="county" label="County / State" />
                <Input name="postcode" label="Postal Code" />
                <Field name="country">
                    {({ field }) => {
                        const options = getCountryOptions();
                        return countries && options ? (
                            <Select
                                defaultValue={getCountryDefaultValue(
                                    field,
                                    options
                                )}
                                name={field.name}
                                label="Country"
                                options={options}
                                placeholder="Select a country"
                                required
                            />
                        ) : (
                            <p className="help-text">Loading...</p>
                        );
                    }}
                </Field>
                <Input
                    name="calendarId"
                    label="Acuity Calendar ID"
                />
                <Input name="locationUrl" label="what3words Reference" help="e.g. repay.salsa.sticks" />
                <Input name="latitude" label="Latitude" required />
                <Input name="longitude" label="Longitude" required />
            </Group>
            <Group name="Contact information" id="contact-info">
                <Input name="website" label="Website" />
                <Input name="telephone" label="Telephone Number" />
                <Input name="email" label="Email" />
            </Group>
            <Group name="Opening hours" id="opening-hours">
                <div className="form-row">
                    {days.map((day) => (
                        <React.Fragment key={day}>
                            <div className="col-12 col-md-6">
                                <TimeField
                                    name={`openingHours.${day.toLowerCase()}.opening`}
                                    label={`${day} Opening`}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <TimeField
                                    name={`openingHours.${day.toLowerCase()}.closing`}
                                    label={`${day} Closing`}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <RichText
                    label="Special Opening Hours"
                    name="specialOpeningHours"
                />
            </Group>
            <Group name="Content and images" id="content-and-images">
                <RichText
                    name="generalInformation"
                    label="General information"
                />
                <LocationImages />
                <Input
                    name="bookOnlineAppointmentUrl"
                    label="Book an online appointment URL"
                />
                <Input
                    name="bookBranchAppointmentUrl"
                    label="Book an in branch appointment URL"
                />
            </Group>

            {locationFeatures?.length > 0 && (
                <Group name="Location Features" id="location-features">
                    {locationFeatures.map((feature) => (
                        <React.Fragment key={`feature_${feature.value}`}>
                            <Checkbox
                                name={`formLocationFeatures.${feature.value}`}
                                label={feature.label}
                            />
                        </React.Fragment>
                    ))}
                </Group>
            )}
        </React.Fragment>
    );
};
