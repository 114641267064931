import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex, Link, Stack, Text } from "@chakra-ui/core";
import { faImages } from "@fortawesome/pro-regular-svg-icons/faImages";
import EmblaCarousel from "../../utils/EmblaCarousel";

let Icon = () => (
  <Flex py={10} justifyContent="center" alignItems="center" w="100%">
    <FontAwesomeIcon icon={faImages} size="6x" />
  </Flex>
);

const ImagesCarouselComponent = ({
  state: {
    showIndicators = false,
    indicatorsPosition = "below",
    indicatorsColour = "#000",
    showArrows = true,
    arrowsColour = "#bababa",
    images = [],
    showTexts = false,
    textColour = "#bababa",
    imageSize = "500x400",
  },
}) => {
  const inStorefront = !window?.location?.pathname.includes("/content/edit/");

  let width = "";
  let height = "";

  switch (imageSize) {
    case "500x400":
      width = "500px";
      height = "200px";
      break;
    case "350x250":
      width = "350px";
      height = "125px";
      break;
    case "250x250":
      width = "250px";
      height = "125px";
      break;
    default:
      width = "250px";
      height = "125px";
  }

  return (
    <>
      {images?.length > 0 ? (
        <EmblaCarousel
          accentColour={indicatorsColour}
          showIndicators={showIndicators}
          arrowColour={arrowsColour}
          showArrows={showArrows}
          indicatorsPosition={indicatorsPosition}
          progressIndicatorStyle={"dots"}
          height={height}
        >
          {images.map((image, idx) => {
            return (
              <Stack
                key={idx}
                position={"relative"}
                width={width}
                height={"100%"}
                cursor={"pointer"}
                marginLeft={"30px"}
                marginTop={"2px"}
                data-testid={`slide-${idx}`}
              >
                <Link
                  rel={image?.openInNewTab ? "nofollow noreferrer" : ""}
                  href={image?.link}
                  target={image?.openInNewTab ? "_blank" : "_self"}
                >
                  {image?.url && image?.url.length > 0 ? (
                    <Stack width={width} height={"100%"} marginBottom={"30px"}>
                      <img
                        src={image?.url}
                        alt={image?.alt || ""}
                        title={image?.title}
                      />
                    </Stack>
                  ) : (
                    <Icon />
                  )}
                  {showTexts && (
                    <>
                      <Text
                        fontSize={"30px"}
                        fontWeight={"400"}
                        lineHeight={"36px"}
                        color={textColour}
                      >
                        {image?.mainText}
                      </Text>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"400"}
                        lineHeight={"20px"}
                        color={textColour}
                      >
                        {image?.subText}
                      </Text>
                    </>
                  )}
                </Link>
              </Stack>
            );
          })}
        </EmblaCarousel>
      ) : inStorefront ? (
        <></>
      ) : (
        <Icon />
      )}
    </>
  );
};

export default ImagesCarouselComponent;
