import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { Stack } from "@chakra-ui/core";

const EmblaCarousel = ({
  accentColour,
  arrowColour,
  showArrows,
  showIndicators,
  indicatorsPosition,
  children,
  height,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    breakpoints: {
      "(min-width: 500px)": {
        align: "start",
      },
    },
    skipSnaps: false,
  });

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );
  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onInit = useCallback((emblaApi) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <Stack paddingX={"20px"}>
      {showIndicators && indicatorsPosition === "above" && (
        <Stack isInline alignItems={"center"} justifyContent={"center"}>
          {scrollSnaps.map((_, index) => (
            <Stack
              key={index}
              onClick={() => scrollTo(index)}
              style={{
                backgroundColor:
                  index === selectedIndex ? accentColour : "#D8D8D8",
              }}
              width={"15px"}
              height={"15px"}
              borderRadius={"9999px"}
              cursor={"pointer"}
            />
          ))}
        </Stack>
      )}
      <Stack
        position={"relative"}
        width={"100%"}
        overflow={"hidden"}
        ref={emblaRef}
      >
        <Stack isInline>{children}</Stack>

        {showArrows && (
          <>
            <Stack
              role="button"
              onClick={scrollNext}
              width={"25px"}
              height={"25px"}
              borderRadius={"9999px"}
              cursor={"pointer"}
              position={"absolute"}
              top={height}
              right={"15px"}
              display={nextBtnDisabled ? "none" : { base: "none", md: "flex" }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{
                  color: arrowColour,
                  fontSize: "24px",
                }}
              />
            </Stack>
            <Stack
              role="button"
              onClick={scrollPrev}
              width={"25px"}
              height={"25px"}
              borderRadius={"9999px"}
              cursor={"pointer"}
              position={"absolute"}
              top={height}
              left={"15px"}
              display={prevBtnDisabled ? "none" : { base: "none", md: "flex" }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                  color: arrowColour,
                  fontSize: "24px",
                }}
              />
            </Stack>
          </>
        )}
      </Stack>
      {showIndicators && indicatorsPosition === "below" && (
        <Stack isInline alignItems={"center"} justifyContent={"center"}>
          {scrollSnaps.map((_, index) => (
            <Stack
              key={index}
              onClick={() => scrollTo(index)}
              style={{
                backgroundColor:
                  index === selectedIndex ? accentColour : "#D8D8D8",
              }}
              width={"15px"}
              height={"15px"}
              borderRadius={"9999px"}
              cursor={"pointer"}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default EmblaCarousel;
