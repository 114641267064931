/* eslint-disable */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { HydraTable, SelectFilter, TimeAgo } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";

import { GET_LIST, useClient } from "@peracto/client";
import { IMPORT_TYPES, PRODUCT_VARIANT_IMPORT_TYPES } from "../util";

const ProductImportList = () => {
  const { client } = useClient();
  const config = useConfig();
  const formatDate = config.get("user_format_date", config.get("format_date"));
  const { products } = config.get("features", {});

  const importTypes = [...IMPORT_TYPES];

  if (products?.productVariants) {
    importTypes.push(...PRODUCT_VARIANT_IMPORT_TYPES);
  }

  if (
    Array.isArray(products?.customImportTypes) &&
    products.customImportTypes?.length > 0
  ) {
    importTypes.push(...products.customImportTypes);
  }

  const getLabel = (value) => {
    const found = importTypes.find((type) => type.value === value);
    return found ? found.label : "-";
  };

  const columns = useMemo(() => [
    {
      Header: "Import Type",
      accessor: "importType",
      Filter: (props) => (
        <SelectFilter label="Import Type" options={importTypes} {...props} />
      ),
      Cell: ({ row, cell: { value } }) => (
        <Link to={`/products/import/${row.original.originId}`}>
          {getLabel(value)}
        </Link>
      ),
    },
    {
      Header: "Source Type",
      accessor: "sourceType",
    },
    {
      Header: "Processed",
      accessor: "processedCount",
    },
    {
      Header: "Errors",
      accessor: "errorCount",
    },
    {
      Header: "Total",
      accessor: "totalCount",
    },
    {
      Header: "Created By",
      accessor: "user.email",
      id: "createdBy",
      Cell: ({ cell: { value } }) => value || "-",
    },
    {
      Header: "Created",
      accessor: "createdAt",
      Cell: ({ cell: { value } }) => {
        const date = new Date(value);
        const validDate = !!date.getDate() === true;

        return value && validDate ? (
          <TimeAgo title={formatDate(date)} date={date} />
        ) : (
          "-"
        );
      },
    },
    {
      Header: "% Complete",
      id: "percentComplete",
      Cell: ({ row }) =>
        row.original.totalCount > 0
          ? `${Math.round(
              (row.original.processedCount / row.original.totalCount) * 100
            )}%`
          : "-",
    },
  ]);

  const onFetchData = (options) =>
    client(GET_LIST, "imports", {
      ...options,
      filter: {
        importType: importTypes.map((type) => type.value),
        ...options.filter,
      },
    });

  return (
    <HydraTable
      columns={columns}
      onFetchData={onFetchData}
      fixedRowCount={10}
      noResultsMessage="No product data has been imported"
      nestedTable={true}
    />
  );
};

export default ProductImportList;
