import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";
import {
    GET_ONE,
    UPDATE,
    CREATE,
    useClient,
    getSchemaFromResource,
} from "@peracto/client";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { useConfig } from "@peracto/peracto-config";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { useSettings } from "@peracto/peracto-hooks";
import cloneDeep from "lodash/cloneDeep";

import {
    formatDataForForm,
    formatDataForAPI,
    convertPricesToCurrency,
    convertPricesToIntegers,
    checkForDuplicateOptions,
    DEFAULT_DISPLAY_TYPES,
} from "../util";

const KitchenEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit User Generated Content">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link
                        className="btn btn-outline-primary"
                        to="/kitchens"
                        data-testid="back-to-kitchens"
                    >
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to User Generated Content
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    );
};

const KitchenEdit = ({ KitchenForm }) => {
    const { client, getResource } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();

    const { id } = useParams();
    const pathname = `/kitchens/${id}`;

    const config = useConfig();
    const { kitchens } = config.get("features", {});

    const additionalPriceTypes = kitchens?.additionalPriceTypes ?? [];
    const showPricesAsCurrency = kitchens?.showPricesAsCurrency ?? true;

    const [kitchen, setKitchen] = useState();

    const { values } = useSettings();

    const display = values?.display;

    const DISPLAY_OPTIONS =
        display?.length > 0 ? display : DEFAULT_DISPLAY_TYPES;

    const fetchKitchen = async () => {
        setLoading(true);
        try {
            const { data, response } = await client(GET_ONE, "kitchens", {
                id: pathname,
            });

            let formatted = formatDataForForm(data, DISPLAY_OPTIONS);

            if (showPricesAsCurrency) {
                formatted = convertPricesToCurrency(
                    formatted,
                    additionalPriceTypes
                );
            }

            setKitchen(formatted);
            setLoading(false);

            if (response.status === 404) {
                setRedirect("/kitchens");
            }
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setLoading(false);
            setRedirect("/kitchens");
        }
    };

    useEffect(() => {
        fetchKitchen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const schema = getSchemaFromResource(getResource("kitchens")).shape({
        mpn: yup.string().nullable(),
        gtin: yup.string().nullable(),
        "attribute-kitchen_name": yup.string().required(),
        sku: yup.string().required(),
        slug: yup.string().required(),
        taxRate: yup.string().required(),
        bulkPrices: yup.array().of(
            yup.object().shape({
                value: yup.number(),
                quantity: yup.number(),
            })
        ),
    });

    const onSubmit = async (data, actions, redirect = null) => {
        const cloneData = cloneDeep(data);
        const hasDuplicates = checkForDuplicateOptions(data.options, actions);

        if (!hasDuplicates) {
            let formattedData;

            if (showPricesAsCurrency) {
                formattedData = convertPricesToIntegers(
                    data,
                    additionalPriceTypes
                );
                formattedData = formatDataForAPI({
                    data: formattedData,
                    displayFields: DISPLAY_OPTIONS,
                    additionalPriceTypes,
                });
            } else {
                formattedData = formatDataForAPI({
                    data,
                    displayFields: DISPLAY_OPTIONS,
                    additionalPriceTypes,
                });
            }

            const headers = {};
            if (formattedData?.saveAndIndex) {
                headers["peracto-index-kitchen"] = true;
            }
            delete formattedData.saveAndIndex;

            actions.setSubmitting(false);

            try {
                await client(
                    UPDATE,
                    "kitchens",
                    {
                        id: pathname,
                        data: formattedData,
                    },
                    headers
                );
                actions.setSubmitting(false);
                if (redirect) {
                    setRedirect(redirect);
                } else {
                    fetchKitchen();
                }
                toast.success("User Generated Content successfully updated!");
            } catch (e) {
                if (e?.error?.body?.violations?.length > 0) {
                    // Display errors for invalid fields
                    e.error.body.violations.map((error) => {
                        return actions.setFieldError(
                            error.propertyPath,
                            error.message
                        );
                    });
                }
                console.error(e);
                toast.error(
                    e?.error?.body?.hasOwnProperty("hydra:description")
                        ? e.error.body["hydra:description"]
                        : "Whoops, there was a problem..."
                );
                actions.setValues(cloneData);
                actions.setSubmitting(false);
            }
        } else {
            actions.setSubmitting(false);
        }
    };

    const onDuplicate = async (data) => {
        let formattedData;

        if (showPricesAsCurrency) {
            formattedData = convertPricesToIntegers(data, additionalPriceTypes);
            formattedData = formatDataForAPI({
                data: formattedData,
                displayFields: DISPLAY_OPTIONS,
                isDuplicate: true,
                additionalPriceTypes,
            });
        } else {
            formattedData = formatDataForAPI({
                data,
                displayFields: DISPLAY_OPTIONS,
                isDuplicate: true,
                additionalPriceTypes,
            });
        }

        try {
            const response = await client(CREATE, "kitchens", {
                data: formattedData,
            });

            // If the response is a URL, we need to extract the ID from it, otherwise we can use the ID directly (this is todo with API changes relating to the google search issues)
            if (response.data.id.includes("https://")) {
                const id = response.data.id.split("/kitchens/").pop();
                setRedirect(`/kitchens/${id}`);
            } else {
                setRedirect(response.data.id);
            }

            toast.success("User Generated Content successfully duplicated!");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    if (loading) {
        return (
            <KitchenEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </KitchenEditContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/kitchens"
                message="The User Generated Content you're looking for could not be found"
                buttonLabel="Go to User Generated Content"
            />
        );
    }

    return (
        <KitchenEditContainer>
            {redirect && <Redirect push to={redirect} />}
            <Helmet>
                <title>
                    {kitchen?.["attribute-kitchen_name"] ||
                        "User Generated Content"}{" "}
                    | Edit | Peracto
                </title>
            </Helmet>

            <KitchenForm
                values={kitchen}
                onSubmit={onSubmit}
                onDuplicate={onDuplicate}
                schema={schema}
            />
        </KitchenEditContainer>
    );
};

export default KitchenEdit;
