import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import cloneDeep from "lodash/cloneDeep";

import { useConfig } from "@peracto/peracto-config";

import { Heading } from "@peracto/peracto-ui";
import { useSettings } from "@peracto/peracto-hooks";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";

import { MODE_ADD } from "../KitchenForm";

import {
    formatDataForAPI,
    convertPricesToIntegers,
    checkForDuplicateOptions,
    DEFAULT_DISPLAY_TYPES,
} from "../util";

const KitchenAdd = ({ KitchenForm }) => {
    const { client, getResource } = useClient();
    const [redirect, setRedirect] = useState();

    const config = useConfig();
    const { kitchens } = config.get("features", {});

    const {
        values: { display },
    } = useSettings();

    const DISPLAY_OPTIONS =
        display?.length > 0 ? display : DEFAULT_DISPLAY_TYPES;

    const additionalPriceTypes = kitchens?.additionalPriceTypes ?? [];
    const showPricesAsCurrency = kitchens?.showPricesAsCurrency ?? true;

    const schema = getSchemaFromResource(getResource("kitchens")).shape({
        mpn: yup.string().nullable(),
        gtin: yup.string().nullable(),
        sku: yup.string().required(),
        slug: yup.string().required(),
        taxRate: yup.string().required(),
        "attribute-kitchen_name": yup
            .string()
            .typeError("Kitchen Name is a required field")
            .required(),
        bulkPrices: yup.array().of(
            yup.object().shape({
                value: yup.number(),
                quantity: yup.number(),
            })
        ),
    });

    const onSubmit = async (data, actions) => {
        const cloneData = cloneDeep(data);
        let formattedData;

        const hasDuplicates = checkForDuplicateOptions(data.options, actions);

        if (!hasDuplicates) {
            if (showPricesAsCurrency) {
                formattedData = convertPricesToIntegers(
                    data,
                    additionalPriceTypes
                );
                formattedData = formatDataForAPI({
                    data: formattedData,
                    displayFields: DISPLAY_OPTIONS,
                    additionalPriceTypes,
                });
            } else {
                formattedData = formatDataForAPI({
                    data,
                    displayFields: DISPLAY_OPTIONS,
                    additionalPriceTypes,
                });
            }

            const headers = {};

            if (formattedData?.saveAndIndex) {
                headers["peracto-index-kitchen"] = true;
            }
            delete formattedData.saveAndIndex;

            try {
                const response = await client(
                    CREATE,
                    "kitchens",
                    {
                        data: formattedData,
                    },
                    headers
                );

                if (response?.data?.id) {
                    if (response.data.id.includes("https://")) {
                        const id = response.data.id.split("/kitchens/").pop();
                        setRedirect(`/kitchens/${id}`);
                    } else {
                        setRedirect(response.data.id);
                    }
                } else {
                    setRedirect("/kitchens");
                }

                toast.success("Kitchen successfully added!");
                actions.setSubmitting(false);
            } catch (e) {
                if (e?.error?.body?.violations?.length > 0) {
                    // Display errors for invalid fields
                    e.error.body.violations.map((error) => {
                        return actions.setFieldError(
                            error.propertyPath,
                            error.message
                        );
                    });
                }
                console.error(e);
                toast.error(
                    e?.error?.body?.hasOwnProperty("hydra:description")
                        ? e.error.body["hydra:description"]
                        : "Whoops, there was a problem..."
                );
                actions.setValues(cloneData);
                actions.setSubmitting(false);
            }
        } else {
            actions.setSubmitting(false);
        }
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <>
            <Helmet>
                <title>User Generated Content | Add | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Add User Generated Content">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link
                            className="btn btn-outline-primary"
                            to="/kitchens"
                            data-testid="back-to-kitchens"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to User Generated Content
                        </Link>
                    </div>
                </Heading>
                <KitchenForm
                    onSubmit={onSubmit}
                    mode={MODE_ADD}
                    schema={schema}
                />
            </div>
        </>
    );
};

export default KitchenAdd;
