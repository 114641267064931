/* eslint-disable */
import React, { useState, useMemo } from "react";
import { HydraTable, Modal } from "@peracto/peracto-ui";

import { GET_LIST, useClient } from "@peracto/client";

const ProductImportStatusList = ({ data, filter, showAll }) => {
  const { client } = useClient();
  const [dataModal, setDataModal] = useState();

  const errorColumns = [
    {
      Header: "Error Message",
      accessor: "errors",
      id: "errorMessage",
      Cell: ({ cell: { value } }) =>
        value?.length > 0 ? value[0].message : "-",
    },
    {
      Header: "Error Data",
      accessor: "errors",
      id: "errorData",
      Cell: ({ cell: { value } }) =>
        value?.length > 0 ? (
          value[0].extra_data?.errors ? (
            value[0].extra_data?.errors?.map((error, idx) => (
              <p
                className="mb-0"
                key={`${error.property}_${error.message}_${idx}`}
              >
                {error.property}: {error.message}
              </p>
            ))
          ) : value[0].message ? (
            <p className="mb-0">{value[0].message}</p>
          ) : (
            "-"
          )
        ) : (
          "-"
        ),
    },
  ];

  const successColumns = [
    {
      accessor: "data",
      Cell: ({ cell: { value } }) => (
        <button
          className="btn btn-link px-0"
          onClick={() => setDataModal(value)}
        >
          Show Data
        </button>
      ),
    },
  ];

  const additionalColumns = filter.successful ? successColumns : errorColumns;

  const columns = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "data",
        id: "sku",
        Cell: ({ cell: { value } }) =>
          value?.sku ||
          value?.product ||
          value?.primaryProduct ||
          value?.productVariant ||
          "-",
      },
      ...additionalColumns,
    ],
    [showAll]
  );

  const onFetchData = (options) => {
    return client(GET_LIST, `import-lines`, {
      ...options,
      filter,
    });
  };

  return useMemo(
    () => (
      <>
        <HydraTable
          columns={columns}
          onFetchData={onFetchData}
          noResultsMessage="No errors!"
          initialData={data}
        />

        <Modal
          isVisible={!!dataModal}
          title="Showing Row Data"
          close={() => setDataModal(null)}
          buttons={[
            {
              type: "btn-outline-secondary",
              text: "Close",
              action: () => {
                setDataModal(null);
              },
            },
          ]}
        >
          {!!dataModal && (
            <table className="table table-sm text-left">
              {Object.entries(dataModal).map((entry, idx) => {
                return (
                  <tr>
                    <td className={idx === 0 ? "border-top-0" : ""}>
                      <span className="font-weight-bold">{entry[0]}:</span>
                    </td>
                    <td className={idx === 0 ? "border-top-0" : ""}>
                      <span style={{ wordBreak: "break-all" }}>{entry[1]}</span>
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </Modal>
      </>
    ),
    [showAll, dataModal]
  );
};

export default ProductImportStatusList;
