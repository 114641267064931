import { menuItems } from "@peracto/peracto";

import { faBoxFull } from "@fortawesome/pro-regular-svg-icons/faBoxFull";

const ROLE_ADMIN = "ROLE_ADMIN";

export const menu = {
    ...menuItems,
    kitchens: {
        label: "User Generated Content",
        icon: faBoxFull,
        sortOrder: 30,
        roles: [ROLE_ADMIN],
        children: {
            allKitchens: {
                label: "All User Generated Content",
                path: "/kitchens",
                sortOrder: 10,
                roles: [ROLE_ADMIN],
            },
            categories: {
                label: "Categories",
                path: "/categories",
                sortOrder: 30,
                roles: [ROLE_ADMIN],
            },
            attributes: {
                label: "Attributes",
                sortOrder: 40,
                roles: [ROLE_ADMIN],
                children: {
                    allAttributes: {
                        label: "All Attributes",
                        path: "/attributes",
                        sortOrder: 10,
                        roles: [ROLE_ADMIN],
                    },
                    attributeGroups: {
                        label: "Attribute Groups",
                        path: "/attribute-groups",
                        sortOrder: 20,
                        roles: [ROLE_ADMIN],
                    },
                    attributeSets: {
                        label: "Attribute Sets",
                        path: "/attribute-sets",
                        sortOrder: 30,
                        roles: [ROLE_ADMIN],
                    },
                },
            },
        },
    },
};
